import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'quiz',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Flag: () => import(/* webpackChunkName: "flag" */ '@/components/icons/Flag'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  data: () => ({
    quizName: 'Functional Selenium Test Quiz',
    classTitle: '',
    quizList: [
      {
        img: 'https://images.unsplash.com/photo-1633113211821-6ac9c3d160a7?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque',
        question: 'why alwasys me?',
        type: 'MULTIPLE',
        answer: '',
        option: [
          { text: 'pilihan A', isCorrect: true },
          { text: 'pilihan B', isCorrect: true },
          { text: 'pilihan C', isCorrect: true },
          { text: 'pilihan D', isCorrect: true }
        ]
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'MULTIPLE',
        option: [
          { text: 'pilihan A', isCorrect: true },
          { text: 'pilihan B', isCorrect: true },
          { text: 'pilihan C', isCorrect: true },
          { text: 'pilihan D', isCorrect: true }
        ]
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'SHORT'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'LONG'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'SHORT'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'LONG'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'SHORT'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'LONG'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'SHORT'
      },
      {
        img: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus erat tristique venenatis eu neque, dignissim enim.',
        question: 'why alwasys me?',
        answer: '',
        type: 'LONG'
      }
    ]
  }),
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'loading']),
    pageSize() {
      return this.isMobile ? 5 : 10
    },
    userId() {
      return localStorage.getItem('user_id')
    },
    classId() {
      return this.$route.params.id
    }
  },
  created() {
    this.getQuizContent()
  },

  methods: {
    ...mapActions('student', ['uploadFile', 'createAttendance', 'getScheduleDashboard', 'getClassById', 'getMessageList', 'doTheTask', 'doTheExam', 'updateTheTask', 'updateTheExam']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    selectOption(elementName, id, answer, mainIndex) {
      this.quizList[mainIndex].answer = answer
      const checkboxes = document.getElementsByName(elementName)
      checkboxes.forEach((item) => {
        if (parseInt(item.id) !== id) item.checked = false
      })
    },

    selectQuestion(id) {
      const elmnt = document.getElementById(`question${id}`)
      elmnt.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    },

    submitQuiz() {},

    getQuizContent() {
      this.showLoading()
      this.getClassById({ classId: this.classId }).then(
        (response) => {
          this.classTitle = response.name
          this.hideLoading()
        },
        () => {
          this.hideLoading()
        }
      )
    }
  }
}
